<template>
	<page-container :page="page" :locale="locale" class="index">
		<div class="columns column12 align-center">
			<h2>{{ page.title }}</h2>
		</div>

		<nuxt-link class="logo" :to="{ path: localePath('/', locale) }">
			<span>RE-OPENING APRIL</span>
		</nuxt-link>

		<div class="side-nav">
			<div class="nav-container">
				<nuxt-link :to="localePath('groups')">
					<span>{{ $t('groups') }}</span>
				</nuxt-link>
			</div>
		</div>

		<main-content
			v-if="page.content"
			:title="page.title || page.header"
			:content="page.content"
			:extracontent="page.extraContent"
		/>

		<footers :socials="socials" :defaults="defaults[locale].website" />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();

const { locale } = useI18n();
const localePath = useLocalePath();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.logo {
	width: 1200px;
	height: 470px;
	max-width: 80%;
	background: url('~/assets/images/logo-maxwell-cafe-black-full.png') no-repeat center center;
	background-size: 100%;
	margin: 20px 0 101px 50px;
	position: relative;
	z-index: 2;
	display: block;
	padding: 0 0 40px;

	span {
		position: absolute;
		inset: auto 0 -45px auto;
		display: block;
		color: #3a3b3b;
		font-weight: 700;
		letter-spacing: 1px;
		font-size: 26px;
	}
}

.side-nav {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	margin: 0 auto 60px;

	.nav-container {
		width: 300px;
		max-width: 70%;
		text-align: left;
		background: #01613d;
		padding: 10px;
	}

	a {
		color: #f2f3ec;
		text-decoration: none;
		font-family: big-caslon-fb, serif;
		font-size: 20px;
		font-weight: 100;

		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}
}

@media (max-width: 1480px) {
	.logo {
		height: 500px;
	}
}

@media (max-width: 1280px) {
	.logo {
		height: 400px;
	}
}

@media (max-width: 1080px) {
	.logo {
		height: 320px;

		span {
			inset: auto 0 -40px auto;
			font-size: 20px;
		}
	}
}

@media (max-width: 880px) {
	.logo {
		max-width: 100%;
		margin: 60px auto 80px;
		width: 90vw;
		height: 38vw;

		span {
			font-size: 18px;
		}
	}
}
</style>
